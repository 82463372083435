/** @format */

import React, { useContext } from "react";
import { Container, Grid, Typography } from "@mui/material";
import imageset1 from "@images/imageset1.png";
import imageset2 from "@images/imageset2.png";
import imageset3 from "@images/imageset3.png";
import imageset5 from "@images/imageset5.svg";
// import imageset4 from "@images/imageset3.png";
import "@styles/footerstyles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { scrollTop } from "../../helpers/Helperfunctions";
import Loadingcontext from "@parts/Loadingprovider";
import CommonImage from "@components/CommonImage";

const Mocafooter = () => {
  const profile_data = useSelector(
    (state) => state.complete_profile.profile_data
  );
  const navigate = useNavigate()
  const { appVersion } = useContext(Loadingcontext);
  return (
    <div className="footer_background_color" style={{ marginTop: "2rem" }}>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            background: "transparent",
            paddingTop: { md: "5rem", sm: "5rem", xs: "0rem" },
            alignItems: { md: "center", sm: "start", xs: "start" },
          }}
          // alignItems={"center"}
          justifyContent={"space-between"}
          // mt={8}
        >
          <Grid item md={3} sm={4} xs={12}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                {/* <CommonImage src={logo} alt="logo" /> */}
                <CommonImage
                  // src={imageset1}
                  // srcSet={`${imageset1} 640w, ${imageset2} 1280w, ${imageset3} 1920w`}
                  // sizes="
                  // // (max-width: 4000px) 25vw,
                  // (max-width: 1920px) 25vw,
                  // (max-width: 1280px) 100vw,
                  // (max-width: 640px) 100vw,
                  // 320px"
                  // alt="Responsive Image"
                  // className="footer_logo"
                  src={imageset5}
                  className={"yourmoca_logo"}
                  alt="Responsive Image"
                />
                {/* <CommonImage src={mocalogo} style={{width:'100px',height:'100px'}} alt="moca logo" /> */}
              </Grid>
              <Grid
                container
                mt={1}
                sx={{ gap: { md: "1%", sm: "1%", xs: "4%" } }}
              >
                <Grid item md={2} xs={"auto"}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 11.3701C16.1234 12.2023 15.9812 13.0523 15.5937 13.7991C15.2062 14.5459 14.5931 15.1515 13.8416 15.5297C13.0901 15.908 12.2384 16.0397 11.4077 15.906C10.5771 15.7723 9.80971 15.3801 9.21479 14.7852C8.61987 14.1903 8.22768 13.4229 8.09402 12.5923C7.96035 11.7616 8.09202 10.91 8.47028 10.1584C8.84854 9.40691 9.45414 8.7938 10.2009 8.4063C10.9477 8.0188 11.7977 7.87665 12.63 8.00006C13.4789 8.12594 14.2648 8.52152 14.8716 9.12836C15.4785 9.73521 15.8741 10.5211 16 11.3701Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 6.5H17.51"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item md={2} xs={"auto"}>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.54 6.42C22.4212 5.94541 22.1792 5.51057 21.8386 5.15941C21.498 4.80824 21.0707 4.55318 20.6 4.42C18.88 4 12 4 12 4C12 4 5.11996 4 3.39996 4.46C2.92921 4.59318 2.50194 4.84824 2.16131 5.19941C1.82068 5.55057 1.57875 5.98541 1.45996 6.46C1.14518 8.20556 0.991197 9.97631 0.999961 11.75C0.988741 13.537 1.14273 15.3213 1.45996 17.08C1.59092 17.5398 1.83827 17.9581 2.17811 18.2945C2.51794 18.6308 2.93878 18.8738 3.39996 19C5.11996 19.46 12 19.46 12 19.46C12 19.46 18.88 19.46 20.6 19C21.0707 18.8668 21.498 18.6118 21.8386 18.2606C22.1792 17.9094 22.4212 17.4746 22.54 17C22.8523 15.2676 23.0063 13.5103 23 11.75C23.0112 9.96295 22.8572 8.1787 22.54 6.42Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item md={2} xs={"auto"}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item md={2} xs={"auto"}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 9H2V21H6V9Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid item md={2} xs={"auto"}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                      stroke="#333333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={9}
            sm={8}
            xs={12}
            sx={{ marginTop: { md: "0px", sm: "0px", xs: "20px" } }}
          >
            <Grid
              container
              item
              md={3}
              sm={6}
              xs={6}
              direction={"column"}
              spacing={3}
            >
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/allcategories"
                  className="footer_text_style"
                >
                  All Categories
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/postrequest"
                  className="footer_text_style"
                >
                  Post A Request
                </NavLink>
              </Grid>
              {/* <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to={
                    profile_data?.userDetails?.profileStatus
                      ? "/dashboard/pricingplans"
                      : "/mocalogin/profilecreation"
                  }
                  className="footer_text_style"
                >
                  Pricing
                </NavLink>
              </Grid> */}
              <Grid item>
                <NavLink
                  to="#howitwork"
                  className="footer_text_style"
                  onClick={(e) => {
                    e.preventDefault();
                    const workElement = document.getElementById("howitwork");

                    if (workElement) {
                      // Scroll to the element if it exists
                      workElement.scrollIntoView({ behavior: "smooth" });
                    } else {
                      // Navigate to "/home#howitwork"
                      navigate("/home#howitwork");

                      // Scroll to the element after navigation
                      setTimeout(() => {
                        const workElementAfterNavigation =
                          document.getElementById("howitwork");
                        if (workElementAfterNavigation) {
                          workElementAfterNavigation.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }, 2000); // Adjust this timeout as needed
                    }
                  }}
                >
                  How It Works
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/findwork"
                  className="footer_text_style"
                >
                  Find Work
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={3}
              sm={6}
              xs={6}
              direction={"column"}
              spacing={3}
            >
              {/* <Grid item>
                <NavLink to="#" className="footer_text_style">
                  Create Profile
                </NavLink>
              </Grid> */}
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/castingcalls?tab=1"
                  className="footer_text_style"
                >
                  Casting Calls
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/castingcalls?tab=2"
                  className="footer_text_style"
                >
                  Competitions
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/aboutus"
                  className={({ isActive }) =>
                    isActive ? "footer_text_active" : "footer_text_style"
                  }
                >
                  About Us
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/pressandnews"
                  className={({ isActive }) =>
                    isActive ? "footer_text_active" : "footer_text_style"
                  }
                >
                  Press & News
                </NavLink>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={3}
              sm={6}
              xs={6}
              direction={"column"}
              gap={3}
              sx={{ marginTop: { md: "0px", sm: "80px", xs: "24px" } }}
            >
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/terms"
                  className={({ isActive }) =>
                    isActive ? "footer_text_active" : "footer_text_style"
                  }
                >
                  Terms of Service
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/privacypolicy"
                  className={({ isActive }) =>
                    isActive ? "footer_text_active" : "footer_text_style"
                  }
                >
                  Privacy Policy
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/contactus"
                  className="footer_text_style"
                >
                  Contact Us
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink
                  onClick={scrollTop}
                  to="/faqpage"
                  className={({ isActive }) =>
                    isActive ? "footer_text_active" : "footer_text_style"
                  }
                >
                  FAQs
                </NavLink>
              </Grid>
            </Grid>
            {profile_data?.userDetails?.profileStatus && (
              <Grid
                container
                item
                md={3}
                sm={6}
                xs={6}
                direction={"column"}
                gap={3}
                alignSelf={"flex-start"}
                // className="footer_media"
                sx={{ marginTop: { md: "0px", sm: "80px", xs: "24px" } }}
              >
                <Grid item>
                  <NavLink
                    onClick={scrollTop}
                    to="/reportissuepage"
                    className={({ isActive }) =>
                      isActive ? "footer_text_active" : "footer_text_style"
                    }
                  >
                    Report Issues
                  </NavLink>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            mt={10}
            justifyContent={"center"}
            alignSelf={"flex-end"}
            mb={2}
          >
            <Typography variant="p" className="copytext">
              Copyright © 2023. All right reserved @ www.yourmoca.com
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignSelf={"flex-end"}
            mb={2}
          >
            <Typography variant="p" className="copytext">
              {appVersion}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mocafooter;
