import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Customizedinput from "@components/Customizedinput";
import { useSelector } from "react-redux";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { getall_countries } from "../services/Commonservices";
import { Envexports } from "../exports/Envexports";
import CommonImage from "@components/CommonImage";

const Countrycode = (props) => {
  /**
   * @param {boolean}-open for opening the dialog
   * @param {funtion} -close for closing the dialog
   * @param {callback} -countrycode_selection returning callback with selected it.
   * @param {String} -state_name  if a component have multiple dialogs opening then state is in object and it is sending dynamic name and returning to the component.
   * @return {object,string} -returning selected countrycode object and dynamic state_name if sent
   */
  const { open, close, countrycode_selection, state_name, currency } = props;

  const [search, setSearch] = useState("");
  const [country_codes, setCountry_codes] = useState([]);
  const user_data = useSelector((state) => state.authentication.data);
  const fetchcountry_codes = async () => {
    try {
      const countries_res = await getall_countries({
        userId: user_data?.id ? user_data?.id : "",
      });
      if (countries_res?.status) {
        setCountry_codes(countries_res?.data);
      } else {
        customEnqueueSnackbar(countries_res?.message, { variant: "error" });
      }
    } catch (error) {
      customEnqueueSnackbar(error, { variant: "error" });
      console.log(error);
    }
  };
  useEffect(() => {
    fetchcountry_codes();
  }, [props.open]);
  let country_codeslist =
    country_codes.length > 0
      ? country_codes
          .filter(
            (country) =>
              country?.countryName
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              country?.dialing_code.includes(search)
          )
          .map((item, index) => {
            return (
              <>
                {currency ? (
                  <ListItem
                    sx={{ cursor: "pointer" }}
                    secondaryAction={item?.currencySymbol}
                    onClick={() => {
                      countrycode_selection(item, state_name);
                      close();
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ background: "none" }}>
                        <CommonImage
                          src={`${Envexports.flagsbaseurl}${item?.flag}`}
                          width={"60%"}
                          height="40%"
                        />
                      </Avatar>

                      {/* <Avatar sx={{background:'none'}}>
                      <CommonImage src={`${Envexports.flagsbaseurl}${item?.flag}`} width={"60%"} height="40%"/>
                      </Avatar> */}
                      {/* {`${item?.emoji}`} */}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item?.currency}
                      secondary={item?.countryName}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    sx={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      countrycode_selection(item, state_name);
                      close();
                    }}
                  >
                    <ListItemAvatar>{`(${item?.dialing_code})`}</ListItemAvatar>
                    <ListItemText> {item?.countryName}</ListItemText>
                  </ListItem>
                )}
              </>
            );
          })
      : "No list found";
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            position: "fixed",
            top: "15%",
            // left: !props.right ? `${props.left}%` : '',
            // right: props.right ? `${props.right}%` : '',
            m: 0,
            minHeight: 400,
            maxHeight: 400,
            width: 300,
            maxWidth: 275,
            padding: "0rem",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0rem 0.8rem", marginTop: "1rem" }}>
          <Customizedinput
            placeholder="search"
            size="medium"
            autoComplete="off"
            value={search}
            onChange={(e) =>
              setSearch(
                e.target.value
                  .split(" ")
                  .map((word) => {
                    return (
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    );
                  })
                  .join(" ")
              )
            }
            fullWidth
          />
        </DialogTitle>
        <DialogContent>
          <List>{country_codeslist}</List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Countrycode;
