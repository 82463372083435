/** @format */

import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { jwtDecode } from "jwt-decode";
import { getcurrentutctime } from "../services/Commonservices";
import {
  filelimits,
  filelimitsmb,
} from "../components/constants/Userconstants";
import { serverTimestamp, Timestamp } from "firebase/firestore";
/**
 *
 * @param {string} firstname
 * @param {string} lastname
 * @returns contcatination of two strings
 */
const concatinate_displayname = (firstname, lastname) => {
  firstname = firstname ?? "";
  lastname = lastname ?? "";
  return firstname + " " + lastname;
};
/**Diplay gender
 * Male
 * Female
 * others
 */
const gender_text = (type) => {
  let gender_type = parseInt(type);
  return gender_type === 1
    ? "Male"
    : gender_type === 2
    ? "Female"
    : gender_type === 3
    ? "LGBTQ"
    : "";
};

// const api_dateformating = (sel_date) => {
//   if (sel_date === undefined || sel_date === null) return "";
//   const inputDate = new Date(sel_date);
//   // Extract year, month, and day from the Date object
//   const year = inputDate.getFullYear();
//   // JavaScript months are zero-indexed, so we add 1 to get the correct month
//   const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
//   const day = inputDate.getDate().toString().padStart(2, "0");
//   // Form the desired date string in the format YYYY-MM-DD
//   return `${year}-${month}-${day}T01:59:59+00:00`; // T23:59:59+00:00
// };
const api_dateformating = (sel_date) => {
  if (sel_date === undefined || sel_date === null) return "";
  // Convert the selected date into a Date object
  const inputDate = new Date(sel_date);
  // Set time to 23:59:59 local time
  inputDate.setHours(23, 59, 59, 0);
  // Get the year, month, and day
  const year = inputDate.getUTCFullYear();
  // Month is zero-indexed, add 1 to get correct month
  const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
  // Get day and pad with leading zero if necessary
  const day = inputDate.getUTCDate().toString().padStart(2, "0");
  // Get UTC hours, minutes, and seconds
  const hours = inputDate.getUTCHours().toString().padStart(2, "0");
  const minutes = inputDate.getUTCMinutes().toString().padStart(2, "0");
  const seconds = inputDate.getUTCSeconds().toString().padStart(2, "0");
  // Return the date in UTC format
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
};

const requestquote_dateformatting = (sel_date) => {
  if (sel_date === undefined || sel_date === null) return "";
  const inputDate = new Date(sel_date);
  // Extract year, month, and day from the Date object
  const year = inputDate.getFullYear();
  // JavaScript months are zero-indexed, so we add 1 to get the correct month
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  // Form the desired date string in the format YYYY-MM-DD
  return `${day}-${month}-${year}`;
};
function api_date_timeformating(inputDate) {
  const date = new Date(inputDate);

  // Get the UTC date and time components
  const utcYear = date.getUTCFullYear();
  const utcMonth = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const utcDay = date.getUTCDate().toString().padStart(2, "0");
  const utcHours = date.getUTCHours().toString().padStart(2, "0");
  const utcMinutes = date.getUTCMinutes().toString().padStart(2, "0");
  const utcSeconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Construct the UTC format string
  const utcDateString = `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}+00:00`;
  return utcDateString;
}

// Input date string
// const inputDate = 'Fri Feb 23 2024 12:00:00 GMT+0530 (India Standard Time)';
// Convert the date format
// const outputDate = convertDateFormat(inputDate);
// console.log(outputDate); // Output: "2024-02-23T12:00:00+05:30"

const api_gender_data = (val) => {
  return val === "Male" ? 1 : val === "Female" ? 2 : 3;
};
/**
 *
 * @param {date} rawdate-formate 2023-11-28T06:04:24.052Z
 * @returns {date} 28/11/2023
 */
// const display_date = (rawdate) => {
//   if (rawdate === undefined || rawdate === null) return "";
//   const inputDate = new Date(rawdate);
//   const day = String(inputDate.getUTCDate()).padStart(2, "0");
//   const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based,
//   const year = inputDate.getUTCFullYear();
//   return `${day}/${month}/${year}`;
// };
const display_date = (rawdate) => {
  // console.log(rawdate,"--------------------raw date-----------------")
  if (rawdate === undefined || rawdate === null) return "";
  const inputDate = new Date(rawdate);
  // const day = String(inputDate.getUTCDate()).padStart(2, "0");
  // const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
  // const year = inputDate.getUTCFullYear();
  const day = String(inputDate.getDate()).padStart(2, "0");
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
  const year = inputDate.getFullYear();
  // console.log(`${day}/${month}/${year}`,"---date")
  return `${day}/${month}/${year}`;
};
/**
 *
 * @param {date} rawdate-formate 2023-11-28T06:04:24.052Z
 * @returns {date} 14/2/2024 18:49:9
 */
const display_dateTime = (rawdate) => {
  if (rawdate === undefined || rawdate === null) return "";
  const inputDate = new Date(rawdate);
  const day = inputDate.getUTCDate();
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const year = inputDate.getUTCFullYear();
  let hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const seconds = inputDate.getSeconds();
  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12;
  }
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
  // const formattedTime = `${hours}:${minutes} ${period}`; //:${seconds}
  return `${day}/${month}/${year} ${formattedTime}`;
};
/**
 *
 * @param {array} data array of objects
 * @param {name} name to destructure the object
 * @returns comma seperated values for given name
 */
const comma_seperated_values = (data, name) => {
  return data.map((item) => item[name]).join(", ");
};
/**
 * Grouping the categories
 */
const all_categories_grouping = (profiles) => {
  let final_obj = {
    categories: [],
    subcategories: [],
    supersubcategories: [],
  };
  let newobj;
  for (let i = 0; i <= profiles.length - 1; i++) {
    if (Object.keys(profiles[i].supersubcategories).length > 0) {
      const { supersubcategories, ...targetobj } = profiles[i];
      newobj = { ...targetobj, superarr: [profiles[i]?.supersubcategories] };
    } else if (Object.keys(profiles[i].subcategoryDetails).length > 0) {
      const { subcategoryDetails, ...targetobj } = profiles[i];
      newobj = { ...targetobj, subarr: [profiles[i]?.subcategoryDetails] };
    } else {
      newobj = profiles[i];
    }

    // looping
    for (let j = i + 1; j <= profiles.length - 1; j++) {
      if (Object.keys(profiles[j].supersubcategories).length > 0) {
        if (
          profiles[i]?.subcategoryDetails?.id ===
          profiles[j]?.subcategoryDetails?.id
        ) {
          newobj?.superarr.push(profiles[j]?.supersubcategories);
          profiles.splice(j, 1);
          j--; // Adjust the loop index after removing an element
        }
      } else if (Object.keys(profiles[j]?.subcategoryDetails).length > 0) {
        if (
          profiles[i]?.categoryDetails?.id === profiles[j]?.categoryDetails?.id
        ) {
          newobj?.subarr.push(profiles[j]?.subcategoryDetails);
          profiles.splice(j, 1);
          j--; // Adjust the loop index after removing an element
        }
      }
    }

    if (Object.keys(profiles[i].supersubcategories).length > 0) {
      final_obj.supersubcategories.push(newobj);
    } else if (Object.keys(profiles[i]?.subcategoryDetails).length > 0) {
      final_obj.subcategories.push(newobj);
    } else {
      final_obj.categories.push(newobj);
    }
  }
  return final_obj;
};
/**
 *
 * @param {*} profile_details
 * @param {*} user_data
 * @param {*} upload_filespdfs
 * @param {*} profile_image
 * @param {*} sub_category
 * @param {*} portfolio_links
 * @param {*} rate_starts
 * @param {*} sel_languages
 * @param {*} sel_codes
 * @returns
 */
// const cmplete_profile_formdata = (
//   profile_details,
//   user_data,
//   upload_filespdfs,
//   profile_image,
//   sub_category,
//   portfolio_links,
//   // rate_starts,
//   sel_languages,
//   sel_codes
// ) => {
//   let social_mediaobj = {
//     instagramCount: profile_details?.instagramCount ?? "0",
//     fbCount: profile_details?.fbCount ?? "0",
//     instagram: profile_details?.instagram ?? "",
//     facebook: profile_details?.facebook ?? "",
//   };
//   const profile_formdata = new FormData();
//   profile_formdata.append("userId", user_data?.id);
//   profile_formdata.append("subCategoryId", sub_category?.id);
//   profile_formdata.append(
//     "yearsOfExperience",
//     profile_details?.expirience_years === ""
//       ? 0
//       : profile_details?.expirience_years
//   );
//   profile_formdata.append(
//     "availableFrom",
//     profile_details?.from_date
//       ? api_dateformating(profile_details?.from_date)
//       : ""
//   );
//   profile_formdata.append(
//     "availableTo",
//     profile_details?.to_date ? api_dateformating(profile_details?.to_date) : ""
//   );
//   profile_formdata.append("otherProjectList", profile_details?.other_projects);
//   profile_formdata.append(
//     "portfolioOrReelLink",
//     JSON.stringify(portfolio_links)
//   );
//   profile_formdata.append("softwareProficiency", profile_details?.sftwr_prof);
//   profile_formdata.append("projectTypes", profile_details?.projects_type);
//   profile_formdata.append(
//     "servicesOffered",
//     sub_category?.id === 17
//       ? profile_details?.services
//       : [10, 11].includes(sub_category?.id)
//       ? profile_details?.service_types
//       : profile_details?.services_oferd
//   );
//   profile_formdata.append("revisions", profile_details?.revisons);
//   profile_formdata.append("socialMedia", JSON.stringify(social_mediaobj));
//   profile_formdata.append("ourJourney", upload_filespdfs?.our_journey);
//   profile_formdata.append("awardsRecognitions", profile_details?.awards);
//   profile_formdata.append("aboutMePDF", upload_filespdfs?.aboutme);
//   profile_formdata.append("guidelinesPDF", upload_filespdfs?.guidelines);
//   profile_formdata.append("equipmentPDF", upload_filespdfs?.equipments_list);
//   profile_formdata.append("policyPDF", upload_filespdfs?.our_policy);
//   profile_formdata.append("staffCount", profile_details?.staff_num);
//   profile_formdata.append("chefsQualifications", profile_details?.chefs_qual);
//   profile_formdata.append(
//     "logisticsDetails",
//     profile_details?.logistic_details
//   );
//   profile_formdata.append("servingStyle", profile_details?.serving_style);
//   profile_formdata.append("contactInfo", "");
//   profile_formdata.append("vehicleTypes", profile_details?.vehicle_types);
//   // profile_formdata.append("ourPolicy", upload_filespdfs?.our_policy);
//   profile_formdata.append("previousEvents", profile_details?.prev_events);
//   // profile_formdata.append("rateStartsFrom", "");
//   // profile_formdata.append("budgetRange", JSON.stringify(rate_starts));
//   profile_formdata.append("whatsAppNumber", profile_details?.watsapp_num);
//   profile_formdata.append("phoneNumber", profile_details?.phone_num);
//   profile_formdata.append("contactPersonName", profile_details?.c_person_name);
//   profile_formdata.append("profileImage", profile_image);
//   profile_formdata.append("languages", JSON.stringify(sel_languages));
//   profile_formdata.append("experienceofLenses", "");
//   profile_formdata.append(
//     "photographyEquipments",
//     profile_details?.photography_equip
//   );
//   profile_formdata.append("typesofCameraModel", profile_details?.cam_models);

//   profile_formdata.append("typesofLenses", profile_details?.types_oflenses);

//   profile_formdata.append(
//     "listMusicalInstruments",
//     profile_details?.musical_instruments
//   );
//   profile_formdata.append(
//     "audioEquipmentList",
//     profile_details?.audio_equipment
//   );
//   profile_formdata.append("droneModel", profile_details?.drone_models);
//   profile_formdata.append(
//     "aerialCinematographyStyle",
//     profile_details?.aireal_cinematorgraphy_Style
//   );
//   profile_formdata.append(
//     "aerialCinematographyCommercial",
//     profile_details?.cinematography_commercial_license
//   );
//   profile_formdata.append(
//     "makeupProductsBrands",
//     profile_details?.makeup_prodcuts
//   );
//   profile_formdata.append("clothBrands", profile_details?.cloth_brands);
//   profile_formdata.append("voiceoverDemos", upload_filespdfs?.voiceover_demo);
//   profile_formdata.append(
//     "languagesPerformVoiceovers",
//     JSON.stringify(profile_details?.voiceover_languages)
//   );
//   profile_formdata.append("state", profile_details.state?.id);
//   profile_formdata.append("country", profile_details.country?.id);
//   profile_formdata.append("city", profile_details?.city);
//   profile_formdata.append("description", profile_details?.description);
//   profile_formdata.append("height", profile_details?.height?.id ?? null);
//   profile_formdata.append("weight", profile_details?.weight);
//   profile_formdata.append("age", profile_details?.age);
//   profile_formdata.append("gender", api_gender_data(profile_details?.gender));
//   profile_formdata.append(
//     "wtsAppCountryCode",
//     sel_codes?.watsapp_num?.id ?? ""
//   );
//   profile_formdata.append(
//     "artistExperience",
//     profile_details?.experience ?? ""
//   );
//   profile_formdata.append(
//     "skills",
//     profile_details?.skills.length > 0
//       ? JSON.stringify(profile_details?.skills.map((item) => item?.id))
//       : null
//   );
//   profile_formdata.append("company", profile_details?.company_name);
//   // profile_formdata.append("name", profile_details?.name);
//   profile_formdata.append("firstName", profile_details?.firstName);
//   profile_formdata.append("lastName", profile_details?.lastName);
//   profile_formdata.append("typesOfEvents", profile_details?.event_types);
//   profile_formdata.append("aboutUsPdf", upload_filespdfs?.aboutus);
//   profile_formdata.append("typesofCaravan", profile_details?.caravan_types);
//   profile_formdata.append("skinType", profile_details?.skintype?.id ?? null);
//   profile_formdata.append("display_name", profile_details?.displayName);
//   profile_formdata.append("email", profile_details?.email);
//   profile_formdata.append(
//     "phoneNumberStatus",
//     profile_details?.phoneNumberStatus
//   );
//   profile_formdata.append(
//     "whatsAppNumberStatus",
//     profile_details?.whatsAppNumberStatus
//   );
//   profile_formdata.append(
//     "respondTime",
//     profile_details?.responsetime?.id ?? null
//   );
//   profile_formdata.append(
//     "isAboutMePdfDeleted",
//     upload_filespdfs?.isAboutMePdfDeleted
//   );
//   profile_formdata.append(
//     "isGuidelinesPdfDeleted",
//     upload_filespdfs?.isGuidelinesPdfDeleted
//   );
//   profile_formdata.append(
//     "isOurJourneyPdfDeleted",
//     upload_filespdfs?.isOurJourneyPdfDeleted
//   );
//   profile_formdata.append(
//     "isaboutUsPdfDeleted",
//     upload_filespdfs?.isaboutUsPdfDeleted
//   );
//   profile_formdata.append(
//     "websiteAndOnlinePlatforms",
//     profile_details?.websiteAndOnlinePlatforms
//   );
//   profile_formdata.append(
//     "reviewsAndTestimonials",
//     profile_details?.reviewsAndTestimonials
//   );
//   profile_formdata.append("partnerships", profile_details?.partnerships);
//   profile_formdata.append(
//     "servicesOfferedForDigitalMarketing",
//     profile_details?.servicesOfferedForDigitalMarketing.length > 0
//       ? JSON.stringify(
//           profile_details?.servicesOfferedForDigitalMarketing.map(
//             (item, index) => item?.id
//           )
//         )
//       : null
//   );
//   profile_formdata.append(
//     "locationType",
//     JSON.stringify(profile_details?.locationType.map((item) => item?.id))
//   );
//   profile_formdata.append("clientPortfolio",profile_details?.client_portfolio)
//   return profile_formdata;
// };
const cmplete_profile_formdata = (
  profile_details,
  user_data,
  upload_filespdfs,
  profile_image,
  sub_category,
  portfolio_links,
  sel_languages,
  sel_codes
) => {
  let social_mediaobj = {
    instagramCount: profile_details?.instagramCount ?? "0",
    fbCount: profile_details?.fbCount ?? "0",
    instagram: profile_details?.instagram ?? "",
    facebook: profile_details?.facebook ?? "",
  };

  const profile_formdata = new FormData();

  // Append fields only if they are not null
  const appendIfNotNull = (key, value) => {
    if (value !== null) {
      profile_formdata.append(key, value);
    }
  };

  appendIfNotNull("userId", user_data?.id);
  appendIfNotNull("subCategoryId", sub_category?.id);
  appendIfNotNull(
    "yearsOfExperience",
    profile_details?.expirience_years === "" ||
      !profile_details?.expirience_years
      ? ""
      : profile_details?.expirience_years
  );
  appendIfNotNull(
    "availableFrom",
    profile_details?.from_date
      ? api_dateformating(profile_details?.from_date)
      : ""
  );
  appendIfNotNull(
    "availableTo",
    profile_details?.to_date ? api_dateformating(profile_details?.to_date) : ""
  );
  appendIfNotNull("otherProjectList", profile_details?.other_projects);
  appendIfNotNull(
    "portfolioOrReelLink",
    JSON.stringify(
      Array.isArray(portfolio_links) && portfolio_links[0]?.link !== ""
        ? portfolio_links
        : []
    )
  );
  appendIfNotNull("softwareProficiency", profile_details?.sftwr_prof);
  appendIfNotNull("projectTypes", profile_details?.projects_type);
  appendIfNotNull(
    "servicesOffered",
    sub_category?.id === 17
      ? profile_details?.services
      : [11].includes(sub_category?.id) //10,
      ? profile_details?.service_types
      : profile_details?.services_oferd
  );
  appendIfNotNull(
    "serviceofferedMess",
    profile_details?.serviceofferedMess?.length > 0
      ? JSON.stringify(
          profile_details?.serviceofferedMess.map((item) => item?.id)
        )
      : null
  );
  appendIfNotNull("revisions", profile_details?.revisons);
  appendIfNotNull("socialMedia", JSON.stringify(social_mediaobj));
  appendIfNotNull("ourJourney", upload_filespdfs?.our_journey);
  appendIfNotNull("awardsRecognitions", profile_details?.awards);
  appendIfNotNull("aboutMePDF", upload_filespdfs?.aboutme);
  appendIfNotNull("guidelinesPDF", upload_filespdfs?.guidelines);
  appendIfNotNull("equipmentPDF", upload_filespdfs?.equipments_list);
  appendIfNotNull("policyPDF", upload_filespdfs?.our_policy);
  appendIfNotNull("staffCount", profile_details?.staff_num);
  appendIfNotNull(
    "chefsQualifications",
    profile_details?.chefs_qual?.length > 0
      ? JSON.stringify(profile_details?.chefs_qual.map((item) => item?.id))
      : null
  ); // profile_details?.chefs_qual
  appendIfNotNull("logisticsDetails", profile_details?.logistic_details);
  appendIfNotNull(
    "servingStyle",
    profile_details?.serving_style?.length > 0
      ? JSON.stringify(profile_details?.serving_style.map((item) => item?.id))
      : null
  ); // profile_details?.serving_style
  appendIfNotNull("contactInfo", "");
  appendIfNotNull("vehicleTypes", profile_details?.vehicle_types);
  appendIfNotNull("previousEvents", profile_details?.prev_events);
  appendIfNotNull("whatsAppNumber", profile_details?.watsapp_num);
  appendIfNotNull("phoneNumber", profile_details?.phone_num);
  // appendIfNotNull("contactPersonName", profile_details?.c_person_name);
  appendIfNotNull("profileImage", profile_image);
  appendIfNotNull("languages", JSON.stringify(sel_languages));
  appendIfNotNull("experienceofLenses", "");
  appendIfNotNull("photographyEquipments", profile_details?.photography_equip);
  appendIfNotNull("typesofCameraModel", profile_details?.cam_models);
  appendIfNotNull("typesofLenses", profile_details?.types_oflenses);
  appendIfNotNull(
    "listMusicalInstruments",
    profile_details?.musical_instruments
  );
  appendIfNotNull("audioEquipmentList", profile_details?.audio_equipment);
  appendIfNotNull("droneModel", profile_details?.drone_models);
  appendIfNotNull(
    "aerialCinematographyStyle",
    profile_details?.aireal_cinematorgraphy_Style
  );
  appendIfNotNull(
    "aerialCinematographyCommercial",
    profile_details?.cinematography_commercial_license
  );
  appendIfNotNull("makeupProductsBrands", profile_details?.makeup_prodcuts);
  appendIfNotNull("clothBrands", profile_details?.cloth_brands);
  appendIfNotNull("voiceoverDemos", upload_filespdfs?.voiceover_demo);
  appendIfNotNull("demoTracks", upload_filespdfs?.demoTracks);
  appendIfNotNull(
    "languagesPerformVoiceovers",
    JSON.stringify(profile_details?.voiceover_languages)
  );
  appendIfNotNull("state", profile_details.state?.id);
  appendIfNotNull("country", profile_details.country?.id);
  appendIfNotNull("city", profile_details?.city);
  appendIfNotNull("description", profile_details?.description);
  appendIfNotNull("height", profile_details?.height?.id ?? null);
  appendIfNotNull("weight", profile_details?.weight);
  appendIfNotNull("age", profile_details?.age);
  appendIfNotNull("gender", api_gender_data(profile_details?.gender));
  appendIfNotNull("wtsAppCountryCode", sel_codes?.watsapp_num?.id ?? "");
  appendIfNotNull("artistExperience", profile_details?.experience ?? "");
  appendIfNotNull(
    "skills",
    profile_details?.skills.length > 0
      ? JSON.stringify(profile_details?.skills.map((item) => item?.id))
      : null
  );
  appendIfNotNull("company", profile_details?.company_name);
  appendIfNotNull("firstName", profile_details?.firstName);
  appendIfNotNull("lastName", profile_details?.lastName);
  appendIfNotNull("typesOfEvents", profile_details?.event_types);
  appendIfNotNull("aboutUsPdf", upload_filespdfs?.aboutus);
  appendIfNotNull(
    "typesofCaravan",
    profile_details?.caravan_types?.length > 0
      ? JSON.stringify(profile_details?.caravan_types.map((item) => item?.id))
      : null
  ); //profile_details?.caravan_types
  appendIfNotNull("studioType", profile_details?.studioType?.id ?? null);
  appendIfNotNull("skinType", profile_details?.skintype?.id ?? null);
  appendIfNotNull("display_name", profile_details?.displayName);
  appendIfNotNull("email", profile_details?.email);
  appendIfNotNull("phoneNumberStatus", profile_details?.phoneNumberStatus);
  appendIfNotNull(
    "whatsAppNumberStatus",
    profile_details?.whatsAppNumberStatus
  );
  appendIfNotNull("respondTime", profile_details?.responsetime?.id ?? null);
  appendIfNotNull("isAboutMePdfDeleted", upload_filespdfs?.isAboutMePdfDeleted);
  appendIfNotNull(
    "isGuidelinesPdfDeleted",
    upload_filespdfs?.isGuidelinesPdfDeleted
  );
  appendIfNotNull(
    "isOurJourneyPdfDeleted",
    upload_filespdfs?.isOurJourneyPdfDeleted
  );
  appendIfNotNull("isaboutUsPdfDeleted", upload_filespdfs?.isaboutUsPdfDeleted);
  appendIfNotNull(
    "websiteAndOnlinePlatforms",
    profile_details?.websiteAndOnlinePlatforms
  );
  appendIfNotNull(
    "reviewsAndTestimonials",
    profile_details?.reviewsAndTestimonials
  );
  appendIfNotNull("partnerships", profile_details?.partnerships);
  appendIfNotNull(
    "servicesOfferedForDigitalMarketing",
    profile_details?.servicesOfferedForDigitalMarketing.length > 0
      ? JSON.stringify(
          profile_details?.servicesOfferedForDigitalMarketing.map(
            (item, index) => item?.id
          )
        )
      : null
  );
  appendIfNotNull(
    "locationType",
    profile_details?.locationType?.length > 0
      ? JSON.stringify(profile_details?.locationType.map((item) => item?.id))
      : null
  );
  appendIfNotNull("clientPortfolio", profile_details?.client_portfolio);
  appendIfNotNull(
    "isvoiceOverDemosPdfDeleted",
    upload_filespdfs?.isvoiceOverDemosPdfDeleted
  );
  appendIfNotNull("isdemoTracksDeleted", upload_filespdfs?.isdemoTracksDeleted);
  return profile_formdata;
};

/**
 *
 * @param {string} inputString
 * @returns formatted string by removing special characters and replacing the spaces with '_'(undrscore)
 */
const formatString = (inputString) => {
  // Replace special characters with an empty string
  let stringWithoutSpecialChars = inputString.replace(/[^\w\s]/gi, "");

  // Replace spaces with underscores
  let finalString = stringWithoutSpecialChars.replace(/\s+/g, "_");

  return finalString;
};

const report_time_conversion = (dateString) => {
  const date = new Date(dateString);

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  const timeString = `${hours}:${minutes}`;
  return timeString;
};

function formatDate(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(inputDate);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
}
// Function to check if the access token is expired
function isAccessTokenExpired() {
  const expiration = localStorage.getItem("token");
  const authDecodedData = jwtDecode(expiration);
  const currentTimeInMilliseconds = new Date().getTime();
  const expirationTimeInSeconds = Number(authDecodedData.exp);
  const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000; // Convert to milliseconds
  // Check if the token has expired
  const hasExpired = currentTimeInMilliseconds > expirationTimeInMilliseconds;
  return hasExpired;
}

// checking empty params
// It will get an object in the function below. It will return true if even one parameter has a value; else, it will return false.
const isSocialMediaNotEmpty = (socialMedia) => {
  for (const key in socialMedia) {
    if (socialMedia[key].trim() !== "") {
      return true; // If any property is not empty, return true
    }
  }
  return false; // All properties are empty, return false
};

// Checking for the subscription plans
const usersubscription_check = (profiledata, userdata) => {
  if (Object.keys(userdata).length === 0) {
    customEnqueueSnackbar("Please login to your account/Register new user.", {
      variant: "warning",
    });
    return false;
  } else if (Object.keys(profiledata).length === 0) {
    customEnqueueSnackbar("please create an account to continue.", {
      variant: "warning",
    });
    return false;
  } else if (
    profiledata === undefined ||
    Object.keys(profiledata?.plan).length === 0 ||
    profiledata.plan === null ||
    profiledata.plan === undefined
  ) {
    customEnqueueSnackbar("Please subscribe to your account.", {
      variant: "warning",
    });
    return false;
  } else {
    return true;
  }
};

// this is to download documents
const handleDownload_document = (documentUrl, documentName) => {
  try {
    const s3Url = documentUrl;
    // Create a link element
    const link = document.createElement("a");
    link.href = s3Url;
    link.target = "_blank";
    link.download = documentName;
    // Append the link to the document
    document.body.appendChild(link);
    // Trigger a click on the link to start the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading document:", error);
  }
};

/**
 * Formatting the loogking for array to send data to api
 * @param {array} lookingfor
 * @returns array of objects with each object updated with height,skin_tone with their respective id's
 */
const lookingfor_apiformatter = async (lookingfor) => {
  const formatted_lookingfor = lookingfor.map((obj) => ({
    gender: api_gender_data(obj.gender),
    age: obj.age,
    height: obj.height.id, // Replace "height" with its "id"
    skinTone: obj.skinTone.id, // Replace "skin_tone" with its "id"
    // category: obj?.category?.id,
    subCategory: obj?.subCategory?.id,
    roleDescription: obj.roleDescription,
    roleName: obj.roleName,
    id: obj?.id,
  }));
  return formatted_lookingfor;
};

/**
 * Display formatting for looking for data in edit case
 * @param {object} lookingfor
 * @returns array of object to display the data
 */
const lookingfor_displayformatter = (lookingfor) => {
  const formatted_lookingfor = lookingfor.map((obj) => ({
    id: obj?.id,
    gender: gender_text(obj.gender),
    age: obj.age,
    roleName: obj?.roleName,
    height: obj.heightDetails, // Replace "height" with its heightDetails
    skinTone: obj.skinTypeDetails, // Replace "skin_tone" with its skinTypeDetails
    category: obj?.categoryDetails,
    subCategory: obj?.subCategoryDetails,
    roleDescription: obj.roleDescription,
  }));
  return formatted_lookingfor;
};

const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
};

// array of number
function arrayOfNumbers(from, to) {
  const result = [];
  for (let i = from; i <= to; i++) {
    result.push(i.toString());
  }
  return result;
}

function transformString(inputString) {
  const camelCaseString = inputString
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
  // Capitalize the first letter
  return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1);
}

//Handle over all application storage
const StorageClient = {
  //will get storage details based on key automatically parse the data
  getItem: (key) => {
    try {
      const serializedItem = localStorage.getItem(key);
      return serializedItem ? JSON.parse(serializedItem) : undefined;
    } catch (error) {
      console.error(`Error getting item from Storage: ${error.message}`);
      return undefined;
    }
  },
  //will set storage item with key and value
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting item in Storage: ${error.message}`);
    }
  },
  //will destroy all application storage
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing Storage: ${error.message}`);
    }
  },
};

function calculateDaysDifference(fromDate, toDate) {
  if (!fromDate || !toDate) {
    return "";
  }
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const timeDifference = endDate - startDate;
  const daysDifference = Math.round(timeDifference / oneDay) + 1;
  return daysDifference;
}

/**
 *
 * @param {integer} type
 * @returns string
 */
const payment_terms_Text = (type, days) => {
  let payment_terms = parseInt(type);
  return payment_terms === 1
    ? "Daily"
    : payment_terms === 2
    ? "Project"
    : payment_terms === 3
    ? `Number of days ${days ? `(${days})` : ""}`
    : "";
};

/**
 * Checking the filetype using mime type in files uploaded
 * @param {object} file
 * @returns integer
 */
const filetype_checking = async (file) => {
  // Check the file's MIME type
  const fileType = file.type;

  // Check if the file is an image
  if (fileType.startsWith("image")) {
    console.log("File is an image");
    return 2;
    // Handle image file
  }
  // Check if the file is a video
  else if (fileType.startsWith("video")) {
    console.log("File is a video");
    return 3;
    // Handle video file
  }
  // Check if the file is a PDF
  else if (fileType === "application/pdf") {
    console.log("File is a PDF");
    return 4;
    // Handle PDF file
  }
  // File type is not recognized
  else {
    console.log("Unsupported file type");
    return 5;
    // Handle unsupported file type
  }
};
/**
 * Thumbnail extraction from the video
 * @param {object} uploadfile
 * @returns
 */
const handleVideoUpload = async (file, seekTo = 0.0) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
};

const hexToRgba = (hex, alpha) => {
  const hexColor = hex.replace(/^#/, "");
  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**Getting the time from the created data like 1 min ago,1hour,1day,1 month,1 year */
const timeAgo = (created) => {
  const providedDate = new Date(created);
  const today = new Date();
  // Calculate the difference in milliseconds
  const timeDifference = today.getTime() - providedDate.getTime();
  // Convert the difference to days, hours, minutes, and seconds
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  const hoursDifference = Math.floor(
    (timeDifference % (1000 * 3600 * 24)) / (1000 * 3600)
  );
  const minutesDifference = Math.floor(
    (timeDifference % (1000 * 3600)) / (1000 * 60)
  );
  const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
  // Display the result
  // if (daysDifference > 0) {
  //   return `${daysDifference} days ago.`;
  // } else if (hoursDifference > 0) {
  //   return `${hoursDifference}:${minutesDifference} mins ago.`;
  // } else if (minutesDifference > 0) {
  //   return `${minutesDifference}:${secondsDifference} secs ago.`;
  // } else {
  //   return `${secondsDifference} secs ago.`;
  // }
  if (daysDifference > 0) {
    return `${daysDifference} days ago.`;
  } else if (hoursDifference > 0) {
    // Format hours and minutes
    const hours = String(hoursDifference).padStart(2, "0");
    const minutes = String(minutesDifference).padStart(2, "0");
    return `${hours}:${minutes} mins ago.`;
  } else if (minutesDifference > 0) {
    // Format minutes and seconds
    const minutes = String(minutesDifference).padStart(2, "0");
    const seconds = String(secondsDifference).padStart(2, "0");
    return `${minutes}:${seconds} secs ago.`;
  } else {
    // Format seconds
    const seconds = String(secondsDifference).padStart(2, "0");
    return `${seconds} secs ago.`;
  }
};

function ratingValueToLinearProgress(starValue, totalValue, intoValue) {
  return (starValue / totalValue) * intoValue || 0;
}

const locationFormat = (city, state, country) => {
  return `${
    city !== "" && city !== null && city !== undefined ? city + ", " : ""
  }${
    state !== null && state !== undefined && state !== "" ? state + ", " : ""
  }${country || ""}`;
};

const rateStartsFromPlus = (ratePlus, maxValue) => {
  return ratePlus || ratePlus === 0
    ? ratePlus === maxValue
      ? `${ratePlus.toLocaleString("en-IN")}+`
      : ratePlus.toLocaleString("en-IN")
    : "";
};

function isYouTubeLink(link) {
  return (
    link !== "www.youtube.com" && // Check if link contains 'youtu.be'
    (link.includes("youtube.com") || // Check if link contains 'youtube.com'
      link.includes("youtu.be"))
  );
}

/**
 * @param link: gets yourtube link everytime/Retrieving the id of the youtube video
 * @param type: returns the link based on type, 1 for thumbnail, 2 for video
 */
function getYouTubeVideoId(link, type) {
  if (!isYouTubeLink(link)) {
    return null; // Return null if the link is not a YouTube link
  }
  const url = new URL(link);
  let videoId = url.searchParams.get("v");
  if (!videoId) {
    const parts = url.pathname.split("/");
    videoId = parts[parts.length - 1].split("?")[0];
  }
  if (type === 1) {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  } else if (type === 2) {
    return `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&mute=0`;
  }
}
/**Retrieving the previous date of given date */
const getPreviousDate = (givenDate) => {
  const date = new Date(givenDate);
  if (date.getDate() === new Date().getDate()) {
    return new Date();
  } else {
    date.setDate(date.getDate());
    return date.toISOString().split("T")[0];
  }
};
const price_formatting = (number, locale, symbol) => {
  const { currency = "INR", countryCode = "IN" } = locale;
  if (number === "NA") {
    return `${currency} NA`;
  }
  let formatting_obj = {
    // style: 'currency',
    currency: currency ?? "INR",
    minimumFractionDigits: 0, // Ensures at least two decimal places
    maximumFractionDigits: 0, // Ensures no more than two decimal places
  };
  let currenyc_symbol = symbol ? false : true;
  if (currenyc_symbol) {
    formatting_obj.style = "currency";
  }

  let num = Math.round(parseFloat(number));
  let formattedCurrency = new Intl.NumberFormat(
    `en-${countryCode}`,
    formatting_obj
    //    {
    //     style: 'currency',
    //     currency: currency,
    //     minimumFractionDigits: 2, // Ensures at least two decimal places
    //     maximumFractionDigits: 2  // Ensures no more than two decimal places
    // }
  ).format(num);
  return formattedCurrency;
};
const price_inputformatting = (number, locale) => {
  const { currency, countryCode } = locale;
  if (number === "NA" || number === "" || number === undefined) {
    return ``;
  }
  let formatting_obj = {
    // style: 'currency',
    currency: currency ?? "INR",
    minimumFractionDigits: 0, // Ensures at least two decimal places
    maximumFractionDigits: 0, // Ensures no more than two decimal places
  };
  let num = parseFloat(number);
  let formattedCurrency = new Intl.NumberFormat(
    `en-${countryCode}`,
    formatting_obj
  ).format(num);

  return formattedCurrency;
};

/**converting objects as comma seperated strings  */
const convertObjectToString = (features) => {
  if (!features) {
    return "";
  }
  let finalFeatures = Object.entries(features)
    .map(([key, value]) =>
      value === -1 || value
        ? `${
            [
              "photosCount",
              "videosCount",
              "createAlbumCount",
              "albumPhotosCount",
              "albumVideosCount",
              "equipmentListingsCount",
              "servicesCount",
              "youtubeLinksCount",
            ].includes(key)
              ? value === -1
                ? "Unlimited"
                : value ?? null
              : ""
          } ${transformString(key).replace(/\bcount\b/g, "")}`
        : null
    )
    .filter((feature) => feature !== null);
  return finalFeatures.join(", ");
};

// this function for input uploads
const handleAllFileChange = (event) => {
  event.preventDefault();
  let droppedFiles = [];
  if (event.dataTransfer) {
    // Handle file drop
    droppedFiles = Array.from(event.dataTransfer.files);
  } else {
    // Handle file input change
    // console.log(event.target.files,"files")
    droppedFiles = Array.from(event.target.files);
  }
  if (event.dataTransfer) {
    event.dataTransfer.clearData();
  } else if (event.target) {
    event.target.value = "";
  }
  return droppedFiles;
};

/**Checking for duplicate countryids exists in the array */
const hasDuplicates = (arr) => {
  const countryIds = new Set();
  // return arr.some(country => countryIds.has(country.countryId) || !countryIds.add(country.countryId));
  return arr.some((country) => {
    const id = country.countryId || country.id;
    if (countryIds.has(id)) {
      return true;
    }
    countryIds.add(id);
    return false;
  });
};

const capitalizeletter = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
/**
 * Checking for subscription expiration based on current utc time zone
 * @param {string} expiration
 * @returns boolean
 */
const isSubscriptionExpired = async (expiration) => {
  try {
    const firebaseTimestamp = Timestamp.now();
    const firebaseDate = new Date(
      firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1000000
    );
    const endDate = new Date(expiration);
    const isExpired = firebaseDate.getTime() > endDate.getTime();
    return isExpired;
    // const currenttimeres = await getcurrentutctime()
    // const current = currenttimeres?.datetime
    // const expirationDate = new Date(expiration);
    // const currentDate = new Date(current);
    // return currentDate > expirationDate;
  } catch (err) {
    console.log(err);
  }
};

/**
 *
 * @param {string} filename
 * @returns string
 */
const createUniqueFilename = (filename) => {
  console.log(filename);
  const sanitizedFilename = filename.replace(/\s+/g, "");
  const time = Date.now();
  return {
    uniquefilename: `${time}${sanitizedFilename}`,
    id: time + Math.floor(Math.random() * 900) + 100,
  };
};

/**
 *
 * @param {file} file
 * @param {string} uniqueFilename
 * @returns new file
 */
const createUpdatedFile = (file, uniqueFilename) => {
  return new File([file], uniqueFilename, {
    type: file.type,
    lastModified: file.lastModified,
  });
};

/**
 *
 * @param {files} files array
 * @returns object
 */
const Filessizevalidation = async (files) => {
  const { imagelimit, videolimit } = filelimits;
  const putUrlObjs = [];
  const createupdatereq = [];
  const sizeExceededFiles = [];
  const filesto_upload = {};
  const processFile = async ({
    file,
    limit,
    putUrlObj,
    file_type,
    fileobj,
  }) => {
    // console.log(file, "file");
    const { uniquefilename: uniqueFilename, id } = createUniqueFilename(
      file.name
    );
    const updatedFile = createUpdatedFile(file, uniqueFilename);
    if (file.size > limit) {
      sizeExceededFiles.push(updatedFile);
    } else {
      let videothumbnail = null;
      let thumbnailname = null;
      if (file_type === "video") {
        videothumbnail = await handleVideoUpload(updatedFile, 1.5);
        thumbnailname = uniqueFilename.replace(/\.[^/.]+$/, ".jpg");
      }
      putUrlObj.mediaType = file.type;
      putUrlObj.name = uniqueFilename;
      fileobj.name = uniqueFilename;
      fileobj.id = id;
      fileobj.videoThumbnail = thumbnailname;
      filesto_upload[uniqueFilename] = {
        media: file,
        thumbnailmedia: videothumbnail,
        mediaType: file?.type,
      };
      createupdatereq.push(fileobj);
      putUrlObjs.push(putUrlObj);
    }
  };

  for (let file of files) {
    const { type } = file;
    const putUrlObj = { videoThumbnail: null };
    const fileobj = { videoThumbnail: null };
    if (typeof type === "string") {
      if (type.startsWith("image")) {
        fileobj.type = 1;
        await processFile({
          file,
          limit: imagelimit,
          putUrlObj,
          file_type: "image",
          fileobj,
        });
      } else if (type.startsWith("video")) {
        fileobj.type = 2;
        await processFile({
          file,
          limit: videolimit,
          putUrlObj,
          file_type: "video",
          fileobj,
        });
      }
    }
  }
  return { putUrlObjs, sizeExceededFiles, filesto_upload, createupdatereq };
};
const isFileSizeExceeded = (file) => {
  if (
    file?.type === 1 ||
    (typeof file.type === "string" && file.type.startsWith("image"))
  ) {
    return file.size > filelimits.imagelimit ? 1 : 0;
  } else if (
    file?.type === 2 ||
    (typeof file.type === "string" && file.type.startsWith("video"))
  ) {
    return file.size > filelimits.videolimit ? 1 : 0;
  }
  return 0;
};

const singlefileValidation = async (file) => {
  const { imagelimit, videolimit } = filelimits;
  const fileType = file.type;
  if (fileType.startsWith("image/") && file.size > imagelimit) {
    customEnqueueSnackbar(
      `The selected image exceeds the maximum allowed size of ${filelimitsmb.image} MB. Please choose an image smaller than ${filelimitsmb.image} MB.`,
      { variant: "warning" }
    );
    return false;
  } else if (fileType.startsWith("video/") && file.size > videolimit) {
    customEnqueueSnackbar(
      `The selected video exceeds the maximum allowed size of ${filelimitsmb.video} MB. Please choose an video smaller than ${filelimitsmb.video} MB.`,
      { variant: "warning" }
    );
    return false;
  } else {
    return true;
  }
};
// validating media if there are in limit are not
const processFiles = async (validFiles) => {
  let images = [];
  let videos = [];

  // Iterate over validFiles and validate each file
  for (const file of validFiles) {
    const isValid = await singlefileValidation(file);

    if (isValid) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image") {
        images = [...images, file];
      } else if (fileType === "video") {
        videos = [...videos, file];
      }
    }
  }

  return { images, videos };
};

const transformLocalToApiFormat = (
  localData,
  youtubeLinks,
  languages,
  contactCodes,
  files,
  profile_image,
  apiData
) => {
  const { userDetails, socialMedia, ...other } = apiData;
  const apiDataRes = {
    ...other,
    aboutMePDF: files?.aboutme,
    equipmentPDF: files?.equipments_list,
    guidelinesPDF: files?.guidelines,
    ourJourney: files?.our_journey,
    policyPDF: files?.our_policy,
    voiceoverDemos: files?.voiceover_demo,
    aboutUsPdf: files?.aboutus,
    //
    yearsOfExperience: localData?.expirience_years || "",
    availableFrom: localData?.from_date,
    availableTo: localData?.to_date,
    otherProjectList: localData?.other_projects,
    softwareProficiency: localData?.sftwr_prof,
    projectTypes: localData?.projects_type,
    servicesOffered: localData?.services_oferd,
    servicesOffered: localData?.services,
    revisions: localData?.revisons,
    //
    staffCount: localData?.staff_num,
    chefsQualifications: localData?.chefs_qual,
    logisticsDetails: localData?.logistic_details,
    servingStyle: localData?.serving_style,
    vehicleTypes: localData?.vehicle_types,
    previousEvents: localData?.prev_events,
    contactPersonName: localData?.c_person_name,
    languages: localData?.languages,
    photographyEquipments: localData?.photography_equip,
    typesofCameraModel: localData?.cam_models,
    typesofLenses: localData?.types_oflenses,
    listMusicalInstruments: localData?.musical_instruments,
    droneModel: localData?.drone_models,
    //
    aerialCinematographyStyle: localData?.aireal_cinematorgraphy_Style,
    aerialCinematographyCommercial:
      localData?.cinematography_commercial_license,
    makeupProductsBrands: localData?.makeup_prodcuts,
    clothBrands: localData?.cloth_brands,
    languagesPerformVoiceovers: localData?.voiceover_languages,
    //
    description: localData?.description,
    height: localData?.height,
    heightDetails: localData?.height,
    weight: localData?.weight,
    servicesOffered: localData?.service_types,
    serviceofferedMess: localData?.serviceofferedMess,
    studioTypeDetails: localData?.studioType,
    artistExperience: localData?.experience,
    //
    typesOfEvents: localData?.event_types,
    typesofCaravan: localData?.caravan_types,
    audioEquipmentList: localData?.audio_equipment,
    skinType: localData?.skintype,
    skinTypeDetails: localData?.skintype,
    awardsRecognitions: localData?.awards,
    respondTime: localData?.responsetime?.id,
    websiteAndOnlinePlatforms: localData?.websiteAndOnlinePlatforms,
    reviewsAndTestimonials: localData?.reviewsAndTestimonials,
    partnerships: localData?.partnerships,
    servicesOfferedForDigitalMarketing:
      localData?.servicesOfferedForDigitalMarketing ?? [],
    locationType: localData?.locationType ?? [],
    clientPortfolio: localData?.client_portfolio,
    //
    portfolioOrReelLink: youtubeLinks,
    //
    socialMedia: {
      ...socialMedia,
      vimeo: localData?.viemo,
      youtube: localData?.youtube,
      instagramCount: localData?.instagramCount,
      fbCount: localData?.fbCount,
      instagram: localData?.instagram,
      facebook: localData?.facebook,
    },
    userDetails: {
      ...userDetails,
      //
      aboutMePDFUrl: files?.aboutme,
      guidelinesPDFUrl: files?.guidelines,
      ourJourneyPDFUrl: files?.our_journey,
      voiceoverDemosPDFUrl: files?.voiceover_demo,
      aboutUsPDFUrl: files?.aboutus,
      demoTrackName: files?.demoTracks,
      demoTracksUrl: files?.demoTracks,
      //
      whatsAppNumber: localData?.watsapp_num,
      phoneNumber: localData?.phone_num,
      //
      state: localData?.state,
      country: localData?.country,
      city: localData?.city,
      //
      description: localData?.description,
      skills: localData?.skills,
      //
      countryCode: contactCodes?.phone_num,
      wtsAppCountryCode: contactCodes?.watsapp_num,
      //
      languages: languages,
      profileImage: profile_image,
      backgroundImage: localData.backgroundImage,
      display_name: localData?.displayName,
    },
  };
  return apiDataRes;
};
const fileReaderUrl = (file) => {
  if (!file) {
    return;
  }
  console.log(file);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result); // Resolve the promise with the file data
    };

    reader.onerror = (error) => {
      reject(error); // Reject the promise if there's an error
    };

    reader.readAsDataURL(file); // Read the file as a data URL
  });
};
const convertingToUrls = async (apiLocalObj, apiData) => {
  return {
    ...apiLocalObj,
    aboutMePDF:
      apiLocalObj?.aboutMePDF && typeof apiLocalObj?.aboutMePDF !== "string"
        ? await fileReaderUrl(apiLocalObj?.aboutMePDF)
        : apiLocalObj?.aboutMePDF,
    equipmentPDF:
      apiLocalObj?.equipmentPDF && typeof apiLocalObj?.equipmentPDF !== "string"
        ? await fileReaderUrl(apiLocalObj?.equipmentPDF)
        : apiLocalObj?.equipmentPDF,
    guidelinesPDF:
      apiLocalObj?.guidelinesPDF &&
      typeof apiLocalObj?.guidelinesPDF !== "string"
        ? await fileReaderUrl(apiLocalObj?.guidelinesPDF)
        : apiLocalObj?.guidelinesPDF,
    ourJourney:
      apiLocalObj?.ourJourney && typeof apiLocalObj?.ourJourney !== "string"
        ? await fileReaderUrl(apiLocalObj?.ourJourney)
        : apiLocalObj?.ourJourney,
    policyPDF:
      apiLocalObj?.policyPDF && typeof apiLocalObj?.policyPDF !== "string"
        ? await fileReaderUrl(apiLocalObj?.policyPDF)
        : apiLocalObj?.policyPDF,
    voiceoverDemos:
      apiLocalObj?.voiceoverDemos &&
      typeof apiLocalObj?.voiceoverDemos !== "string"
        ? await fileReaderUrl(apiLocalObj?.voiceoverDemos)
        : apiLocalObj?.voiceoverDemos,
    aboutUsPdf:
      apiLocalObj?.aboutUsPdf && typeof apiLocalObj?.aboutUsPdf !== "string"
        ? await fileReaderUrl(apiLocalObj?.aboutUsPdf)
        : apiLocalObj?.aboutUsPdf,
    userDetails: {
      ...apiLocalObj?.userDetails,
      profileImage:
        typeof apiLocalObj?.userDetails?.profileImage === "string"
          ? apiLocalObj?.userDetails?.profileImage
          : URL.createObjectURL(apiLocalObj?.userDetails?.profileImage),
      aboutMePDFUrl:
        apiLocalObj?.userDetails?.aboutMePDFUrl &&
        typeof apiLocalObj?.userDetails?.aboutMePDFUrl !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.aboutMePDFUrl)
          : apiData?.userDetails?.aboutMePDFUrl?.url,
      guidelinesPDFUrl:
        apiLocalObj?.userDetails?.guidelinesPDFUrl &&
        typeof apiLocalObj?.userDetails?.guidelinesPDFUrl !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.guidelinesPDFUrl)
          : apiData?.userDetails?.guidelinesPDFUrl?.url,
      ourJourneyPDFUrl:
        apiLocalObj?.userDetails?.ourJourneyPDFUrl &&
        typeof apiLocalObj?.userDetails?.ourJourneyPDFUrl !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.ourJourneyPDFUrl)
          : apiData?.userDetails?.ourJourneyPDFUrl?.url,
      voiceoverDemosPDFUrl:
        apiLocalObj?.userDetails?.voiceoverDemosPDFUrl &&
        typeof apiLocalObj?.userDetails?.voiceoverDemosPDFUrl !== "string"
          ? {
              url: await fileReaderUrl(
                apiLocalObj?.userDetails?.voiceoverDemosPDFUrl
              ),
            }
          : apiData?.userDetails?.voiceoverDemosPDFUrl,
      aboutUsPDFUrl:
        apiLocalObj?.userDetails?.aboutUsPDFUrl &&
        typeof apiLocalObj?.userDetails?.aboutUsPDFUrl !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.aboutUsPDFUrl)
          : apiData?.userDetails?.aboutUsPDFUrl?.url,
      demoTrackName:
        apiLocalObj?.userDetails?.demoTrackName &&
        typeof apiLocalObj?.userDetails?.demoTrackName !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.demoTrackName)
          : apiLocalObj?.userDetails?.demoTrackName,
      demoTracksUrl:
        apiLocalObj?.userDetails?.demoTracksUrl &&
        typeof apiLocalObj?.userDetails?.demoTracksUrl !== "string"
          ? await fileReaderUrl(apiLocalObj?.userDetails?.demoTracksUrl)
          : apiData?.userDetails?.demoTracksUrl,
    },
  };
};

export {
  concatinate_displayname,
  gender_text,
  api_dateformating,
  requestquote_dateformatting,
  api_date_timeformating,
  comma_seperated_values,
  display_date,
  api_gender_data,
  all_categories_grouping,
  cmplete_profile_formdata,
  isSocialMediaNotEmpty,
  usersubscription_check,
  handleDownload_document,
  scrollTop,
  arrayOfNumbers,
  transformString,
  formatString,
  report_time_conversion,
  formatDate,
  lookingfor_apiformatter,
  lookingfor_displayformatter,
  isAccessTokenExpired,
  StorageClient,
  calculateDaysDifference,
  payment_terms_Text,
  filetype_checking,
  handleVideoUpload,
  hexToRgba,
  timeAgo,
  display_dateTime,
  ratingValueToLinearProgress,
  locationFormat,
  rateStartsFromPlus,
  getYouTubeVideoId,
  isYouTubeLink,
  getPreviousDate,
  price_formatting,
  price_inputformatting,
  convertObjectToString,
  handleAllFileChange,
  hasDuplicates,
  capitalizeletter,
  isSubscriptionExpired,
  Filessizevalidation,
  isFileSizeExceeded,
  singlefileValidation,
  processFiles,
  transformLocalToApiFormat,
  convertingToUrls,
  fileReaderUrl,
};
