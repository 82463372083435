import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";

export const artistCardStyles = makeStyles({
  cardContainer: {
    borderRadius: "1rem !important",
    backgroundColor: "#F2F2F2 !important",
    height: "100%",
  },
  cardImage: {
    width: "100%",
    aspectRatio: 16 / 16,
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    objectFit: "contain !important",
    backgroundColor: "#d9d9d9", // #FFF
  },
  cardContainerflowter: {
    position: "absolute",
    width: "100%",
    bottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 0.5rem",
    "& .MuiChip-root": {
      backgroundColor: "#F2F2F2",
      color: "#009ACA",
      maxWidth: "90px",
      "& .MuiChip-label": {
        paddingLeft: "12px",
        paddingRight: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "@media (min-width: 0px) and (max-width: 650px)": {
      "& .MuiChip-root": {
        maxWidth: "70px",
        fontSize: "10px",
        height: "25px",
      },
    },
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "0.2rem 0.5rem",
      paddingBottom: "1rem !important",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      gap: "0.3rem",
    },
  },
  favChecked: {
    color: "white",
    backgroundColor: "#F2994A",
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
    "@media (min-width: 0px) and (max-width: 650px)": {
      "&.MuiSvgIcon-root": {
        width: "18px",
        height: "18px",
      },
      padding: "0.4rem",
    },
  },
  favUnchecked: {
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    color: "white",
    backgroundColor: "#333333",
    borderRadius: "50%",
    "@media (min-width: 0px) and (max-width: 650px)": {
      "&.MuiSvgIcon-root": {
        width: "18px",
        height: "18px",
      },
      padding: "0.4rem",
    },
  },
  cardHeading: {
    "&.MuiTypography-root": {
      fontSize: "1.5rem",
      fontWeight: 700,
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "@media (min-width: 0px) and (max-width: 650px)": {
      "&.MuiTypography-root": {
        fontSize: "1rem",
      },
    },
  },
  cardPricing: {
    "&.MuiTypography-root": {
      fontSize: "1rem",
      color: "#333333",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& span": {
      color: "#04BEF8",
      fontWeight: 700,
      fontSize: "1.2rem",
    },
    "@media (min-width: 0px) and (max-width: 650px)": {
      "&.MuiTypography-root": {
        fontSize: "0.8rem",
      },
      "& span": {
        fontSize: "0.8rem",
      },
    },
  },
  profileContainer: {
    "&.MuiListItem-root": {
      padding: "0px",
    },
    "& .MuiAvatar-root": {
      width: "45px !important",
      height: "45px !important",
    },
    "& span.MuiTypography-root": {
      fontSize: 0,
    },
    "& .MuiRating-root label": {
      fontSize: "1.2rem",
    },
    "& .MuiTypography-root": {
      "& .reviews": {
        color: "#828282",
        "& span": {
          color: "#333333",
        },
      },
    },
    "@media (min-width: 0px) and (max-width: 650px)": {
      "& .MuiRating-root": {
        fontSize: "1rem",
      },
      "& .MuiTypography-root": {
        "& .reviews": {
          fontSize: "12px",
        },
      },
    },
    "@media (min-width: 0px) and (max-width: 350px)": {
      "& .MuiAvatar-root": {
        width: "35px !important",
        height: "35px !important",
      },
      "& .MuiListItemAvatar-root": {
        minWidth: "40px",
      },
    },
  },
});

export const serviceCardStyles = makeStyles({
  cardContainer: {
    borderRadius: "1rem !important",
    backgroundColor: "#F2F2F2 !important",
    height: "100%",
  },
  cardImage: {
    width: "100%",
    aspectRatio: 16 / 9,
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    objectFit: "contain !important",
    backgroundColor: "#d9d9d9", // #FFF
  },
  cardFlim: {
    width: "calc( 100% - 2px ) !important",
    aspectRatio: 16 / 9,
    padding:'0.5rem 0rem 0.5rem 0rem',
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    objectFit: "contain !important",
    backgroundColor: "#FFF",
    border: "1px solid black",
  },
  cardContainerflowter: {
    position: "absolute",
    width: "100%",
    bottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 0.5rem",
    "& .MuiChip-root": {
      backgroundColor: "#F2F2F2",
      color: "#009ACA",
      "& .MuiChip-label": {
        paddingLeft: "12px",
        paddingRight: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "0.2rem 0.5rem",
      paddingBottom: "1rem !important",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      gap: "0.2rem",
    },
  },
  favChecked: {
    color: "white",
    backgroundColor: "#F2994A",
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
  },
  favUnchecked: {
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    color: "white",
    backgroundColor: "#333333",
    borderRadius: "50%",
  },
  cardHeading: {
    "&.MuiTypography-root": {
      fontSize: "1.5rem",
      fontWeight: 700,
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  cardDespriction: {
    "&.MuiTypography-root": {
      maxWidth: "100%",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      WebkitLineClamp: "2",
    },
  },
  cardPricing: {
    "&.MuiTypography-root": {
      fontSize: "1rem",
      color: "#333333",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& span": {
      color: "#04BEF8",
      fontWeight: 700,
      fontSize: "1.2rem",
    },
  },
});

export const findworkCardStyles = makeStyles({
  cardContainer: {
    borderRadius: "1rem !important",
    backgroundColor: "#F2F2F2 !important",
    boxShadow: "none !important",
    cursor: "pointer",
    "&:hover": {
      scale: "1.05",
      backgroundColor: "#ebe8e8 !important",
      "& .MuiCardHeader-root": {
        backgroundColor: "#afeeff",
      }
    },
  },
  cardHeader: {
    backgroundColor: "#d6f6ff",
    padding: "0.8rem 1.5rem !important",
    borderRadius: "1rem",
    "& .MuiCardHeader-avatar": {
      maxWidth: "60%",
      "& .budget_title": {
        color: "#333333",
        fontSize: "0.9rem",
        fontWeight: "400",
      },
      "& .budget": {
        color: "#333333",
        fontSize: "1.2rem",
        fontWeight: "600",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .MuiCardHeader-action": {
      maxWidth: "50%",
      "& .days_needed": {
        color: "#333333",
        fontSize: "0.9rem",
        fontWeight: "600",
      },
    },
  },
  favChecked: {
    color: "white",
    backgroundColor: "#F2994A",
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
  },
  favUnchecked: {
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
  },
  cardBody: {
    padding: "1rem 1.5rem !important",
    "& .content_title": {
      color: "#828282",
      fontWeight: "400",
    },
    "& .content_value": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      textAlign: "end",
      fontWeight: "400",
      fontSize: "1rem",
    },
  },
  cardFooter: {
    padding: "1rem 1.5rem !important",
    paddingTop: "0rem !important",
  },
  profileContainer: {
    "&.MuiListItem-root": {
      padding: "0px",
    },
    "& .MuiAvatar-root": {
      width: "45px !important",
      height: "45px !important",
    },
    "& span.MuiTypography-root": {
      fontSize: 0,
    },
    "& .MuiRating-root label": {
      fontSize: "1.2rem",
    },
    "& .MuiTypography-root": {
      "& .reviews": {
        color: "#828282",
        "& span": {
          color: "#333333",
        },
      },
    },
  },
});

export const castingcallCardStyles = makeStyles({
  cardContainer: {
    borderRadius: "1rem !important",
    backgroundColor: "#D8F6FF !important",
    boxShadow: "none !important",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      scale: "1.05",
    },
  },
  cardHeader: {
    padding: "0.5rem !important",
    "& .MuiCardHeader-action": {
      marginTop: "0px",
      marginRight: "0px",
      marginBottom: "0px",
    },
  },
  favChecked: {
    color: "white",
    backgroundColor: "#F2994A",
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
  },
  favUnchecked: {
    width: "25px",
    height: "25px",
    padding: "0.6rem",
    borderRadius: "50%",
  },
  cardBody: {
    padding: "1rem !important",
    paddingTop: "0rem !important",
    "& .title": {
      fontSize: "1.3rem",
      fontWeight: 700,
      textAlign: "center",
      marginBottom: "0.5rem",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      overflowWrap: "break-word",
      maxWidth: "100%",
      wordBreak: "break-word",
    },
    "& .content_title": {
      color: "#828282",
    },
    "& .content_value": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      textAlign: "end",
    },
  },
  cardFooter: {
    padding: "1rem !important",
    paddingTop: "0rem !important",
  },
  profileContainer: {
    "&.MuiListItem-root": {
      padding: "0px",
      "& .profile_name": {
        fontSize: "1rem",
        fontWeight: 700,
        color: "#333333",
      },
    },
    "& .MuiAvatar-root": {
      width: "45px",
      height: "45px",
    },
    "& span.MuiTypography-root": {
      fontSize: 0,
    },
    "& .MuiRating-root label": {
      fontSize: "1.2rem",
    },
    "& .MuiTypography-root": {
      "& .reviews": {
        color: "#828282",
        "& span": {
          color: "#333333",
        },
      },
    },
  },
});

export const competitioncardStyles =  makeStyles(
  {
    cardContainer: {
      borderRadius: "1rem !important",
      backgroundColor: `${style_exports.basicplan_bgcolor}`,
      boxShadow: "none !important",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      cursor: "pointer",
      "&:hover": {
        scale: "1.05",
      },
    },
    cardHeader: {
      padding: "0.5rem !important",
      "& .MuiCardHeader-action": {
        marginTop: "0px",
        marginRight: "0px",
        marginBottom: "0px",
      },
    },
    favChecked: {
      color: "white",
      backgroundColor: "#F2994A",
      width: "25px",
      height: "25px",
      padding: "0.6rem",
      borderRadius: "50%",
    },
    favUnchecked: {
      width: "25px",
      height: "25px",
      padding: "0.6rem",
      borderRadius: "50%",
    },
    cardBody: {
      padding: "1rem !important",
      paddingTop: "0rem !important",
      paddingBottom:'8rem!important',
      "& .title": {
        fontSize: "1.3rem",
        fontWeight: 700,
        textAlign: "center",
        marginBottom: "0.5rem",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        overflowWrap: "break-word",
        maxWidth: "100%",
        wordBreak: "break-word",
      },
      "& .content_title": {
        color: "#828282",
      },
      "& .content_value": {
        maxWidth: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textAlign: "end",
      },
    },
    cardFooter: {
      padding: "1rem !important",
      paddingTop: "0rem !important",
    },
    profileContainer: {
      "&.MuiListItem-root": {
        padding: "0px",
        "& .profile_name": {
          fontSize: "1rem",
          fontWeight: 700,
          color: "#333333",
        },
      },
      "& .MuiAvatar-root": {
        width: "45px",
        height: "45px",
      },
      "& span.MuiTypography-root": {
        fontSize: 0,
      },
      "& .MuiRating-root label": {
        fontSize: "1.2rem",
      },
      "& .MuiTypography-root": {
        "& .reviews": {
          color: "#828282",
          "& span": {
            color: "#333333",
          },
        },
      },
    },
  }
)
export const visitedOrFavCardStyles = makeStyles({
  cardContainer: {
    gap: "0.6rem",
    alignItems: "center",
    backgroundColor: "#F2F2F2",
    padding: "0.6rem 0.90rem",
    borderRadius: "1rem",
    flexWrap: "nowrap !important",
    cursor: "pointer",
  },
  cardImage: {
    width: "50px !important",
    height: "50px !important",
  },
  cardTitle: {
    fontWeight: "700 !important",
    fontSize: "1rem !important",
    color: "#2E2E35",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardSubTitle: {
    fontWeight: "400 !important",
    fontSize: "0.8rem !important",
    color: "#04BEF8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
